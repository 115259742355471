import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./index.css"; // Import the CSS file for animations and styling
import {
  CHAINS,
  SUPPORTED_CHAINS,
  BSC_CHAIN_ID,
} from "../../constants/blockchainConstants";
import Web3 from "web3";
import useMetaMask from "../../hooks/useMetamask";
import ABI from "../../assest/abiJson/ABI.json";
import toast, { Toaster } from "react-hot-toast";
import { MetaMaskPrompt } from "../metamask/InstallAlert";

const BuyToken = () => {
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState("");
  const [bhrtBal, setBhrtBal] = useState(0);
  const [transactionHash, setTransactionHash] = useState("");
  const [coinBalance, setCoinBalance] = useState(0);
  const [maxBuyLimitError, setMaxLimitError] = useState();
  const [calculatedGasFee, setcalculatedGasFee] = useState();
  const [rateOfToken, setRateOfToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const { account, connect, isConnected, switchToBSC, networkId, error,provider } =
    useMetaMask();

    useEffect(()=>{
if (error) {
if (error === "MetaMask not installed") {
  setShowPrompt(true)
}else{
  toast.error(error)
}
}
    },[error])
  useEffect(() => {
    if (provider) {
    if (!isConnected ) {
      connect().then(async () => {
        if (networkId !== BSC_CHAIN_ID) {
          await switchToBSC(BSC_CHAIN_ID);
        }
      });
    } else {
      if (networkId !== BSC_CHAIN_ID) {
        switchToBSC(BSC_CHAIN_ID).then();
      }
    }
  }
  },[provider]);

  useEffect(() => {
    if (account && networkId === BSC_CHAIN_ID) {
      getCoinBalance().then();
      getUserBHRTBalance().then();
      getRate().then();
    }
  }, [account, networkId, BSC_CHAIN_ID]);

  useEffect(() => {
    if (transactionHash && account) {
      getCoinBalance().then();
      getUserBHRTBalance().then();
      getRate().then();
    }
  }, [transactionHash]);
  useEffect(() => {
    if (amount) getCalculatedGasFee(amount);
  }, [amount]);

  // Handler to go to the next step
  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  // Handler to go to the previous step
  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const getCoinBalance = async () => {
    // Create a new instance of Web3 using the BSC testnet RPC URL
    const web3 = getWeb3();
    try {
      const balance = await web3.eth.getBalance(account);
      const balanceInBNB = web3.utils.fromWei(balance, "ether");
      setCoinBalance(Number(balanceInBNB).toFixed(4));
      console.log(`BNB Balance on Testnet: ${balanceInBNB}`);
    } catch (error) {
      console.error("Error fetching balance:", error);
    }
  };
  const getContractInstance = () => {
    if (error) {
      if (error === "MetaMask not installed") {
        setShowPrompt(true)
      }else{
        toast.error(error)
      }
      return
    }
    const web3 = getWeb3();

    const contract = CHAINS[BSC_CHAIN_ID].contractAddress;
    const abi = ABI;
    console.log(contract);

    const tokenContract = web3 ? new web3.eth.Contract(abi, contract) : null;
    return tokenContract;
  };
  const getUserBHRTBalance = async () => {
    const contract = getContractInstance();
    const bal = await contract.methods.balanceOf(account).call();
    const decimals = await contract.methods.decimals().call();
    const balance = Number(bal) * Math.pow(10, -Number(decimals));
    setBhrtBal(balance.toFixed(4));
  };
  const getCalculatedGasFee = async (amount) => {
    if (error) {
      if (error === "MetaMask not installed") {
        setShowPrompt(true)
      }else{
        toast.error(error)
      }
      return
    }
    if (!isNaN(amount) && amount > 0) {
      const web3 = getWeb3();
      try {
        setMaxLimitError(false);
        const amountInWei = await web3.utils.toWei(
          (+amount).toFixed(18),
          "ether"
        );
        const tokenContract = getContractInstance();
        var gasPrice = await web3.eth.getGasPrice(); // estimate the gas price
        var transactionObject = {
          from: account,
          to: CHAINS[BSC_CHAIN_ID].contractAddress,
          value: amountInWei,
          gasPrice: gasPrice,
          data: tokenContract.methods.buyTokens().encodeABI(),
        };

        var gasLimit = await web3.eth.estimateGas(transactionObject);
        await web3.eth
          .getGasPrice()
          .then((gasPrice) => {
            let gasFee = Number(gasLimit) * Number(gasPrice);
            console.log("Gas Fee in Wei: " + gasFee);
            console.log(
              "Gas Fee in Ether: " +
                web3.utils.fromWei(gasFee.toString(), "ether")
            );
            const fee = (Number(gasFee) * Math.pow(10, -18)).toFixed(8)
            setcalculatedGasFee(fee); //
          })
          .catch((error) => {
            console.log("Error getting gas price: " + error);
            setcalculatedGasFee(0);
          });
      } catch (error) {
        console.log("Error getting gas price: " + error);
        if (error?.message?.includes("insufficient funds")) {
          maxBuyLimitError(true);
        }
        setcalculatedGasFee(0);
      }
    } else {
      setcalculatedGasFee(0);
    }
  };
  const getRate = async () => {
    try {
      const tokenContract = getContractInstance();
      if (tokenContract) {
        const rate = await tokenContract.methods.getRate().call();
        setRateOfToken(1 / Number(rate));
      }
    } catch (error) {
      console.error("Error fetching rate:", error);
    }
  };
  const getWeb3 = () => {
    if (typeof window.web3 !== "undefined") {
      return new Web3(window.web3.currentProvider);
    } else {
      return new Web3.providers.HttpProvider("http://localhost:3000");
    }
  };

  const buyTokens = async () => {
    if (error) {
      if (error === "MetaMask not installed") {
        setShowPrompt(true)
      }else{
        toast.error(error)
      }
      return
    }
    if(loading) return;

    setLoading(true)
    let contract = getContractInstance();
    const web3 = getWeb3();
    const gasPrice = await web3.eth.getGasPrice();
    const value = await web3.utils.toWei(Number(amount).toFixed(18), "ether");
    await contract.methods.buyTokens(account).send({
        from: account,
        // to: CHAINS[BSC_CHAIN_ID].contractAddress,
        value: value,
        gasPrice: gasPrice,
        // data: contract.methods.buyTokens().encodeABI(),
      })
      .then(async (res) => {
        setLoading(false)
        toast.success("Transaction Successful!");
        setTransactionHash(res.transactionHash);
        handleNext();
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message)
        setLoading(false)
      });
  };
  const getChainMeta = () => {
    return CHAINS[BSC_CHAIN_ID];
  };

  useEffect(() => {
   console.log(calculatedGasFee);
   
  }, [calculatedGasFee])
  const handleClose = () => {
    setShowPrompt(false);
  };
  return (
    <div className="container ">
       <MetaMaskPrompt show={showPrompt} handleClose={handleClose} />
      <Toaster/>
      {/* Stepper Header */}
      <div className="stepper-container">
        {/* Step 1 Circle */}
        <div className="stepper-step">
          <div className={`stepper-circle ${step >= 1 ? "active" : ""}`}>1</div>
          <span>Token Info</span>
        </div>
        {/* Line between Step 1 and Step 2 */}
        <div className={`stepper-line ${step >= 2 ? "active" : ""}`}></div>

        {/* Step 2 Circle */}
        <div className="stepper-step" style={{alignItems:"center"}}>
          <div className={`stepper-circle ${step >= 2 ? "active" : ""}`}>2</div>
          <span>Conversion</span>
        </div>
        {/* Line between Step 2 and Step 3 */}
        <div className={`stepper-line ${step >= 3 ? "active" : ""}`}></div>

        {/* Step 3 Circle */}
        <div className="stepper-step" style={{alignItems:"end"}}>
          <div className={`stepper-circle ${step >= 3 ? "active" : ""}`}>3</div>
          <span>Confirmation</span>
        </div>
      </div>

      {/* Step Content */}
      <TransitionGroup>
        <CSSTransition key={step} timeout={500} classNames="fade">
          <div className="card p-4">
            {/* Step 1: Token Information */}
            {step === 1 && (
              <div>
                <div className="feature_info pl-0">
                  <h3 className="pl-0">Token Information</h3>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <strong>Token Name:</strong> BHRT
                  </div>
                  <div className="col">
                    <strong>Token Balance:</strong> {bhrtBal} BHRT
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <strong>Available Coin (BNB):</strong> {coinBalance} BNB
                  </div>
                  <div className="col">
                    <strong>Contract Address:</strong>{" "}
                    {getChainMeta()?.contractAddress}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <strong>Current Price (BNB):</strong> 0.00005 BNB
                  </div>
                </div>
                {/* Chain Type Input */}
                <div className="divider"></div>
                <div className="form-group">
                  <label> Amount</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter amount in BNB"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-end align-items-end">
                  <button className="green_btn" onClick={()=>{
                    if(!amount){
                      toast.error("Please enter amount")
                    }else{
                    handleNext()
                    }
                    }} >
                    Next
                  </button>
                </div>
              </div>
            )}

            {/* Step 2: Conversion Details */}
            {step === 2 && (
              <div>
                <div className="feature_info pl-0">
                  <h3 className="pl-0">You will get</h3>
                </div>
                <p>
                  <strong>Amount of BHRT Token:</strong>{" "}
                  {amount * (1 / rateOfToken) || 0} BHRT
                </p>
                <div className="divider"></div>
                <div className="row mb-3">
                  <div className="col">
                    <strong>Entered BNB:</strong> {amount || 0} BNB
                  </div>
                  <div className="col">
                    <strong>Gas Fee:</strong> {calculatedGasFee} BNB
                  </div>
                  <div className="col">
                    <strong>Total BNB:</strong>{" "}
                    {amount ? parseFloat(amount) + calculatedGasFee : 0} BNB
                  </div>
                </div>
                <div className="divider"></div>
                <div className="d-flex justify-content-between align-items-end">
                  <button className="yellow_btn mr-2" onClick={handlePrevious}>
                    Back
                  </button>
                  <button className="green_btn" disabled={loading} onClick={() => buyTokens()}>
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        wait...
                      </>
                    ) : (
                      "Buy"
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* Step 3: Confirmation */}
            {step === 3 && (
              <div>
                <div className="feature_info pl-0">
                  <h3 className="pl-0">Transaction Confirmation</h3>
                </div>
                <p>
                  <strong>Status:</strong> Success
                </p>
                <p>
                  <strong>Transaction Hash:</strong>{" "}
                  {transactionHash || "0xABC123..."}
                </p>
                <a
                  href={`${CHAINS[BSC_CHAIN_ID].blockExplorerUrls}/tx/${transactionHash}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View on Explorer
                </a>
                <br />
                <div className="divider"></div>
                <div className="d-flex justify-content-between align-items-end">
                  <button className="yellow_btn mr-2" onClick={handlePrevious}>
                    Back
                  </button>
                  <button
                    className="green_btn"
                    onClick={() => {
                      setStep(1);
                      setAmount("");
                    }}
                  >
                    Finish
                  </button>
                </div>
              </div>
            )}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default BuyToken;
