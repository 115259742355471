import React from 'react'

const Footer = () => {
  return (
    <div>
        
        
<footer id='footer'>
<div class="container wow fadeInLeft" data-wow-duration="2s">
<div class="row">
<div class="col-lg-5 col-md-5 footer_link pr-5 ">
<h3>Information</h3>
<p>Bharat Token is a decentralized cryptocurrency designed to empower seamless digital transactions, providing secure, fast, and low-cost financial solutions.</p>

</div>

<div class="col-md-3 col-6 footer_link">
<h3>Quick Links</h3>

<li><a href="#top_banner">Home </a></li>
<li><a href="#tokenomic">Tokenomics</a></li>
<li><a href="#faq">FAQ</a></li>
<li><a href="#">Whitepaper</a></li>

</div>
{/* <div class="col-md-2 col-6 footer_link">
<h3 >Other Links</h3>
<li ><a href="#">Resources </a></li>
<li><a href="#">Charity</a></li>
<li><a href="#">Referral</a></li>
<li><a href="#">Support</a></li>

</div> */}
<div class="col-md-3 col-12 footer_link bottom_social">
<h3>Join the community</h3>
{/* <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut.</p> */}
<br/>
<a href="#" class="text-center"><i class="fa fa-paper-plane-o"></i> </a>
<a href="#" class="text-center"><i class="fa fa-twitter"></i> </a>
<a href="#" class="text-center"><i class="fa fa-facebook"></i> </a>
<a href="#" class="text-center"><i class="fa fa-linkedin"></i> </a>
<a href="#" class="text-center"><i class="fa fa-youtube-play"></i> </a>
</div>


</div>



</div>
</footer>

    </div>
  )
}

export default Footer