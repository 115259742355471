// Chain IDs
export const ETH_CHAIN_ID = '0x1'; // Ethereum Mainnet
export const SEPOLIA_CHAIN_ID = '0xAA36A7'; // Sepolia Testnet
export const BSC_CHAIN_ID = "0x38"//'0x61'; // Binance Smart Chain

// RPC URLs
const ETH_RPC_URL = 'https://mainnet.infura.io/v3/YOUR_INFURA_PROJECT_ID';
const SEPOLIA_RPC_URL = 'https://sepolia.infura.io/v3/YOUR_INFURA_PROJECT_ID';
const BSC_RPC_URL = "https://bsc-dataseed.bnbchain.org";//'https://data-seed-prebsc-1-s1.binance.org:8545';

// Supported Chains 1: Ethereum, Sepolia, Binance Smart Chain
export const SUPPORTED_CHAINS = { ETH_CHAIN_ID, SEPOLIA_CHAIN_ID, BSC_CHAIN_ID };
// Chain Details
export const CHAINS = {
  [ETH_CHAIN_ID]: {
    chainId: ETH_CHAIN_ID,
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [ETH_RPC_URL],
    blockExplorerUrls: ['https://etherscan.io'],
    contractAddress: ""
  },
  [SEPOLIA_CHAIN_ID]: {
    chainId: SEPOLIA_CHAIN_ID,
    chainName: 'Sepolia Testnet',
    nativeCurrency: {
      name: 'Sepolia ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [SEPOLIA_RPC_URL],
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
    contractAddress: ""
  },
  [BSC_CHAIN_ID]: {
    chainId: BSC_CHAIN_ID,
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [BSC_RPC_URL],
    blockExplorerUrls: ['https://bscscan.com'],
    contractAddress: "0x155220036a0488162cd898c6cef63879c050a018" //"0xb372c8242b8da89426bae70a47a21af2775fa982 //testnet"
  },
};