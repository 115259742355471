import React, { useEffect } from "react";
import feature_1 from "../images/feature_1.png";
import yellowshadow from "../images/yellowshadow.png";
import Header from "../Component/Header";
import global_network from '../images/global_network.png'
import BuyToken from "../Component/buy/buyToken";
import Footer from '../Component/Footer'
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';

export const BuyComponent = () => {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  return (
    <>
        {/* <Header/> */}
        {/* <section id="top_banner">
        <div className="container">
          <div className="row">
            <div className="col-md-6 banner_txt wow fadeInLeft mt-3 pe-5" data-wow-duration="1.5s">
              <h1 style={{ fontSize: "54px"}}>Buy BHARAT token</h1>
              <p style={{ color:"#515152"}}>BHARAT is a virtual reality platform powered by the Ethereum blockchain.</p>
            </div>
          </div>
        </div>

        

      </section> */}
      <section id="buy" style={{zIndex:10}}>
        <div className="container text-white">
          <div className="row">
            <div className="col-md-12 wow fadeInUp" data-wow-duration="3s">
            <h1> Buy BHARAT</h1>
              <br/>
              <BuyToken />
            </div>
            {/* <div className="col-md-4 points wow fadeInUp pt-5" data-wow-duration="10s">
              <img className="banner-img mt-5" src={global_network} alt="" />
            </div> */}

          </div>

        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};
