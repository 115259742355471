import React,{useEffect} from 'react'
import { Link,useParams } from 'react-router-dom'
import logo from '../images/logo.png'
const Header = () => {
  const {section} = useParams()
  useEffect(() => {
   if(section){
    const element = document.getElementById(section)
    element.scrollIntoView({behavior:"smooth",block:"start"})
   }
  }, [section])
  
  const scrollToSection = (route)=>{
    
  }
  return (
    <div>
        <header>
  <nav className="navbar navbar-expand-md fixed-top">
    <div className="container container-fluid side_space"> <a className="navbar-brand" href="#"  onClick={()=> scrollToSection("/")}><img src="/images/logo1.png" alt="logo"/></a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="fa fa-bars"></i> </span> </button>
      <div className="collapse navbar-collapse justify-content-end" id="navbarsExampleDefault">
        <ul className="navbar-nav mx-auto">
        <li className="nav-item active"> <Link className="nav-link" style={{ fontSize: '1.2rem' }} to="/home/top_banner">Home </Link> </li>
        <li className="nav-item active"> <Link className="nav-link" style={{ fontSize: '1.2rem' }} to="/home/buy">Buy </Link> </li>
          <li className="nav-item active"> <Link className="nav-link" style={{ fontSize: '1.2rem' }} to="/home/feature">Use Cases </Link> </li>
          <li className="nav-item"> <Link className="nav-link"style={{ fontSize: '1.2rem' }}  to="/home/tokenomic">Tokenomics</Link> </li>  
		  {/* <li className="nav-item"> <Link className="nav-link"style={{ fontSize: '1.2rem' }}  to="/home/address_link">Audit</Link> </li>   */}
		  <li className="nav-item"> <Link className="nav-link" style={{ fontSize: '1.2rem' }} to="/home/stage">Phases</Link> </li>  
		  
        </ul>
		<ul className="navbar-nav">         
		{/* <li className="nav-item"><a className="nav-link red_btn" style={{ fontSize: '1rem' }}  href="#">Login</a> </li> */}
	    <li className="nav-item"><a className="nav-link stroke_btn" style={{ fontSize: '1rem' }}  href="#whitepaper">Whitepaper</a> </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
    </div>
  )
}

export default Header