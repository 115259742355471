import React, { useEffect, useState } from 'react'
import Header from '../Component/Header'
// import global_network from '../images/global_network.png'
import applications from '../images/applications.png'
import content from '../images/content.png'
import advertising from '../images/advertising.png'
import digital from '../images/digital.png'
// import shadow from '../images/shadow.png'
// import chart1 from '../images/chart1.png'
// import chart from '../images/chart.png'
// import chart2 from '../images/chart2.png'
// import chart3 from '../images/chart3.png'
import tokenomic from '../images/tokenomic.png'
import tokenomics from '../images/tokenomics.png'
// import tokenomic2 from '../images/tokenomics2.png'
// import tokenomic3 from '../images/tokenomics3.png'
// import tokenomic4 from '../images/tokenomics4.png'
import tokenomic5 from '../images/tokenomics5.png'
// import tokenomic1 from '../images/tokenomics1.png'
// import tokeninfo from '../images/tokeninfo.png'
// import laptop from '../images/laptop.png'
// import elip from '../images/elip.png'
// import whitepaper from '../images/whitepaper.png'
// import whitepaper1 from '../images/whitepaper1.png'
// import whitepaper2 from '../images/whitepaper2.png'
// import feature_1 from '../images/feature_1.png'
// import news_img from '../images/news_img.png'
// import blockchain from '../images/blockchain.png'
// import yellowshadow from '../images/yellowshadow.png'
 import why from '../images/why.png'
// import Team from '../images/team.png'
// import shape1 from '../images/shape1.png'
// import shape2 from '../images/shape2.png'
// import shape3 from '../images/shape3.png'
// import aboutus from '../images/about-us.png'
import Footer from '../Component/Footer'
import WOW from 'wowjs';
import 'wowjs/css/libs/animate.css';
import { useNavigate } from "react-router-dom"
import global_network from '../images/global_network.png'
import BuyToken from "../Component/buy/buyToken";
import { BuyComponent } from './Buy'
const Home = () => {
  const navigate = useNavigate()


  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    { question: "What is Bharat Coin?", answer: "Bharat Coin is a blockchain-powered digital asset designed for secure transactions, staking, gaming, and decentralized finance (DeFi). Built on Binance Smart Chain (BSC), it offers fast and low-cost transactions. " },
    { question: "How can I buy Bharat Coin?", answer: "You can purchase Bharat Coin during the presale, ICO, or through supported cryptocurrency exchanges once listed. Stay updated on our official website for more details." },
    { question: "Is Bharat Coin secure?", answer: "Yes! Bharat Coin is built on Binance Smart Chain (BSC) with advanced security protocols, smart contract audits, and multi-layer encryption to ensure safe transactions. " },
    { question: "Will Bharat Coin be listed on exchanges?", answer: "Yes! After the ICO, Bharat Coin will be listed on major cryptocurrency exchanges. Stay updated for announcements. " },
    { question: "How can I contact support?", answer: "For any inquiries, visit our official website, social media pages, or join our Telegram community." }
  ];


  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);
  const [timeLeft, setTimeLeft] = useState({
    days: 186,
    hours: 17,
    minutes: 37,
    seconds: 20
  });

  let intervalId;

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateTimeLeft();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const updateTimeLeft = () => {
    setTimeLeft(prevTimeLeft => {
      let { days, hours, minutes, seconds } = prevTimeLeft;

      seconds--;
      if (seconds < 0) {
        seconds = 59;
        minutes--;
        if (minutes < 0) {
          minutes = 59;
          hours--;
          if (hours < 0) {
            hours = 23;
            days--;
            if (days < 0) {
              clearInterval(intervalId);
            }
          }
        }
      }

      return {
        days,
        hours,
        minutes,
        seconds
      };
    });
  };


  return (
    <>
      <Header />

      <section id="top_banner">

        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 banner_txt wow fadeInLeft mt-5" data-wow-duration="2s">
              {/* <h5>#No.1 Crypto platform</h5> */}

              <h1> <span>BHARAT</span><br />Revolutionizing Digital Finance With BHARAT Coin </h1>
              <p className='text-center mb-5'>Bharat Coin is a blockchain-powered financial ecosystem designed to enable secure, transparent, and decentralized digital transactions. Built on the Binance Smart Chain (BSC), it offers a scalable and efficient platform for digital payments, decentralized finance (DeFi), and blockchain-based applications, ensuring fast transactions with low fees.

              </p>
              <div className='text-center mt-5'>
                <button class="buy-btn" onClick={() => navigate("/home/buy")}>Buy BHRT</button>

              </div>

              {/*<p className='text-center mb-5'>The current price of Bharat Coin is $0.00001, and the next price will be $0.00002</p>*/}
            </div>

          </div>
        </div>
        {/* <img class="mx-auto" src={chart} alt=""></img> */}
      </section>

      <section id="stage">

        <div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 wow fadeInLeft" data-wow-duration="2s">


              <h1> Phases</h1>
              <hr className='line' />
              <div>
                <div class="row mt-5">

                  <div class="col">
                    <h2>Phase 1</h2>
                    <hr className='dash-line' />
                    <div className='d-flex justify-content-between mt-5'>
                      <div className='text-start'>
                        <h5>Date</h5>
                        <h4>3 May 2025</h4>
                      </div>
                      <div className='text-end'>
                        <h5>Price</h5>
                        <h4>BNB 0.00009</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <h2>Phase 2</h2>
                    <hr className='dash-line' />
                    <div className='d-flex justify-content-between mt-5'>
                      <div className='text-start'>
                        <h5>Date</h5>
                        <h4>4 Nov 2025</h4>
                      </div>
                      <div className='text-end'>
                        <h5>Price</h5>
                        <h4>BNB 0.00050</h4>
                      </div>
                    </div>
                  </div>

                  <div class="col">
                    <h2>Phase 3</h2>
                    <hr className='dash-line' />
                    <div className='d-flex justify-content-between mt-5'>
                      <div className='text-start'>
                        <h5>Date</h5>
                        <h4>4 Mar 2026</h4>
                      </div>
                      <div className='text-end'>
                        <h5>Price</h5>
                        <h4>BNB 0.00080</h4>
                      </div>
                    </div>
                  </div>

                </div>
                <hr className='line mt-5' />

                {/* <div class="row text-left">
                  <div class="col-md-4">
                    <h2>Step 1</h2>
                    <p>Select the payment method</p>
                    <input type='text' value='BNB' className='form-control'></input>
                  </div>
                  <div class="col-md-8">
                  <h2>Step 2</h2>
                  <p>Enter the Amount of Token You Would Like to Purchase</p>
                  <input type='text' className='form-control'></input>
                  <div className='text-center mt-4'>
                <button class="buy-btn w-100">Connect Wallet</button>

              </div>
                  </div>
                  
                </div> */}
              </div>


            </div>

          </div>
        </div>

      </section>
      
      
        
       
      <section id="about-us" style={{zIndex:10}}>
        <BuyComponent/>
      </section>
      
    


      {/* <!-- feature section start -->  */}
      <section id="feature">
        <div class="container">
          <div class="row text-center">
          
            <h1>Use Cases</h1>
          </div>
          <div class="row mt-5">
            <div class="col-md-6  wow fadeInUp" data-wow-duration="2s">
              <div class="outer_bx feature_bx d-flex">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={applications} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3> Fast & Secure Transactions</h3>
                  <p>Instant, low-cost, and secure payments for everyday use.
                    Send and receive funds quickly with minimal fees, ensuring seamless transactions.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6  wow fadeInUp" data-wow-duration="3s">
              <div class="outer_bx d-flex feature_bx">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={content} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3> Staking & Rewards</h3>
                  <p>Earn passive income by staking Bharat Coin.
                    Lock your tokens and receive rewards while contributing to network security and stability.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 wow fadeInUp" data-wow-duration="4s">
              <div class="outer_bx d-flex feature_bx">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={advertising} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3>Gaming & Digital Assets</h3>
                  <p>Use Bharat Coin in gaming and digital marketplaces.
                    Buy, sell, and trade in-game assets, NFTs, and collectibles securely.</p>
                </div>
              </div>
            </div>

            <div class="col-md-6 wow fadeInUp" data-wow-duration="5s">
              <div class="outer_bx d-flex feature_bx">
                <div class="icon_bx">
                  <div class="gradient_bx">
                    <img src={digital} alt="img" />
                  </div>
                </div>
                <div class="feature_info">
                  <h3>Smart Contracts & DApps </h3>
                  <p>Powering decentralized applications and automation.
                    Develop and interact with blockchain-based applications with full transparency and security. </p>
                </div>
              </div>
            </div>


          </div>
        </div>
      </section>
      {/* <!-- feature section end --> */}


      <section id='tokenomic'>

        <div className='mx-5'>
          <div class="row">
            <div class="col-xl-12 col-lg-12 wow fadeInLeft" data-wow-duration="2s">

              <h1>BHARAT Presale</h1>
              <p>Secure your spot in the presale and receive rewards!</p>
              <img src={tokenomics} />
              <h2><span>Total : </span><br />100 billion BHRT</h2>

            </div>

          </div>
        </div>
        {/* <img class="mx-auto" src={chart} alt=""></img> */}
      </section>

      {/* <section id='tokeninfo'>

        <div>
          <div className='container'>
            

            <div class="wow fadeInLeft" data-wow-duration="2s">
              <div className='mt-5'>
                <h4> <span>1</span>Buy BNB BHRT Tokens</h4>
                <h5>Connect your wallet to purchase $BHRT How to buy?</h5>
                <div className='mt-4'>
                  <button class="buy-btn">Buy BHRT</button>

                </div>
              </div>

              <div style={{ marginTop: "70px" }}>
                <h4> <span>2</span>Stake $BHRT</h4>
                <h5>Navigate to the "Staking" option on the platform. Select a staking duration that suits your preferences (longer durations yield higher rewards)</h5>
              </div>

              <div style={{ marginTop: "70px" }}>
                <h4> <span>3</span>Earn Rewards</h4>
                <h5>Monitor your staking rewards and claim them as per the platform's rules</h5>
              </div>

            </div>

          </div>
        </div>
      </section>  */}



      {/* <section id='rewards'>

        <div class="container">
          <div class="row">
            <div class="wow fadeInLeft" data-wow-duration="2s">

              <h1>Staking Rewards Calendar</h1>
              <p>JA key feature of $BHRT is our Staking Program. By staking the token, investors can earn rewards with an APR of up to 200%, regardless of the token's Price. All returns are generated from the trading pool of our AI-driven trading system.</p>



              <div class="row">
                <div class="col wow fadeInLeft rewardbox bg-stake" data-wow-duration="2s">
                  <h2>30 Days</h2>
                  <h5>APR 50%</h5>
                </div>
                <div class="col wow fadeInLeft rewardbox bg-stake" data-wow-duration="2s">
                  <h2>90 Days</h2>
                  <h5>APR 90%</h5>
                </div>
                <div class="row">
                  <div class="col wow fadeInLeft rewardbox bg-stake" data-wow-duration="2s">
                    <h2>188 Days</h2>
                    <h5>APR 120%</h5>
                  </div>
                  <div class="col wow fadeInLeft text-black rewardbox bg-lightyellow" data-wow-duration="2s">
                    <h2>360 Days</h2>
                    <h5 className='text-black' style={{ background: "#A386AB" }}>APR 200%</h5>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </section> */}


      {/* <section id='laptop'>
        <div>
          <img src={laptop}></img>
        </div>
      </section> */}

      {/* <section id='why'>

        <div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 wow fadeInLeft" data-wow-duration="2s">

              <h1>Why BHARAT ?</h1>
              <p>A key feature of $BHRT is our Dividend Program. By holding the token, investors can enjoy monthly payouts up to 15%, regardless of the token's price. All returns are generated from the trading pool of our AI-driven trading system.</p>
              <img src={why}></img>
            </div>

          </div>
        </div>
      </section> */}


      <section id="faq">
        <div className="mx-5">
          <div className="row">
            <div className="col-xl-12 col-lg-12 wow fadeInLeft" data-wow-duration="2s">
              <h1>FAQ</h1>
              <p>
                BHARAT likes to help! Reach out to our support teams on any of our
                social channels. In the meantime, here's some of our FAQs.
              </p>

              {faqs.map((faq, index) => (
                <div key={index} className="faq-text">
                  <div key={index} className="d-flex justify-content-between">
                    <h4>{index + 1}. {faq.question}</h4>
                    <p
                      style={{ fontSize: "2rem", cursor: "pointer" }}
                      onClick={() => toggleFAQ(index)}
                    >
                      {openIndex === index ? "✖" : "+"}
                    </p>
                  </div>

                  {openIndex === index && <p>{faq.answer}</p>}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id='disclaimer'>

        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-12 wow fadeInLeft" data-wow-duration="2s">

              <h1>Disclaimer</h1>
              <p>Bharat Coin is a digital asset subject to market risks and volatility. This is not financial or investment advice—please conduct your own research before participating. We do not guarantee returns, and users are responsible for securing their wallets and private keys. Participation in presale, ICO, or staking is at your own risk. Bharat Coin is not liable for any losses or regulatory implications. Consult a professional before investing. </p>

            </div>

          </div>
        </div>
        {/* <img class="mx-auto" src={chart} alt=""></img> */}
      </section>



      <Footer />



    </>

  )
}

export default Home